import { Link } from "react-router-dom";
import styled from "styled-components";
import IconButton from "./IconButton";

interface Props {
  children: React.ReactNode;
  to: string;
  disabled?: boolean;
}

const LinkButton: React.FC<Props> = ({ children, to, disabled }) => (
  <StyledLink to={to}>
    <IconButton disabled={disabled}>{children}</IconButton>
  </StyledLink>
);

export default LinkButton;

const StyledLink = styled(Link)`
  text-decoration: none;
`;
