import { useState } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";

import CopyURLButton from "./CopyURLButton";
import IconButton from "./IconButton";
import LinkButton from "./LinkButton";

import { download_link_song_path } from "@/routes";
import { Song, Version } from "@/types/serializers";
import { track } from "@/utils/functions";

import BackIcon from "~/icons/back.svg?react";
import DotsIcon from "~/icons/dots.svg?react";
import DownloadIcon from "~/icons/download.svg?react";

type Props = {
  song: Song;
  allowDownload: boolean;
  currentVersion?: Version;
};

const SongNav: React.FC<Props> = ({ song, allowDownload, currentVersion }) => {
  const [showDropdown, setShowDropdown] = useState(false);

  const { password } = useParams<{ password: string }>();

  return (
    <Container>
      <LinkButton to={song ? `/v/${password}` : "/"}>
        <BackIcon />
      </LinkButton>
      <RightContainer>
        <CopyURLButton />
        {allowDownload && (
          <DropdownContainer
            onMouseEnter={() => setShowDropdown(true)}
            onMouseLeave={() => setShowDropdown(false)}
          >
            <IconButton>
              <DotsIcon />
            </IconButton>
            {showDropdown && (
              <DropdownWrapper>
                <Dropdown>
                  <DropdownItem
                    as="a"
                    href={download_link_song_path(
                      password ?? "",
                      song?.slug ?? ""
                    )}
                    onClick={(e) => {
                      e.stopPropagation();
                      track("song_downloaded_song_nav", {
                        song_id: song?.id,
                      });
                    }}
                  >
                    <DownloadIcon />
                    Download project
                  </DropdownItem>
                  {currentVersion && (
                    <DropdownItem
                      as="a"
                      href={currentVersion.file}
                      download={currentVersion.filename}
                      onClick={(e) => {
                        e.stopPropagation();
                        track("version_downloaded_song_nav", {
                          version_id: currentVersion.id,
                          song_id: currentVersion.song_id,
                        });
                      }}
                    >
                      <DownloadIcon />
                      Download selected mix
                    </DropdownItem>
                  )}
                </Dropdown>
              </DropdownWrapper>
            )}
          </DropdownContainer>
        )}
      </RightContainer>
    </Container>
  );
};

export default SongNav;

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
`;

const RightContainer = styled.div`
  display: flex;
  gap: 8px;
`;

const DropdownContainer = styled.div`
  position: relative;
`;

const DropdownWrapper = styled.div`
  position: absolute;
  top: 100%;
  right: 0;
  padding-top: 8px;
`;

const Dropdown = styled.div`
  position: relative;
  z-index: 10;

  border-radius: 7px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  background: #191919;
  box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.15);

  display: flex;
  padding: 10px;
  flex-direction: column;
  align-items: flex-start;
`;

const DropdownItem = styled.a`
  display: flex;
  align-items: center;
  gap: 12px;
  align-self: stretch;

  padding: 8px 12px;

  white-space: nowrap;

  cursor: pointer;

  border-radius: 10px;

  transition: background-color 0.1s ease;

  text-decoration: none;
  color: inherit;

  &:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }
`;
