import styled from "styled-components";
import logo from "~/icons/logo.png";

import CopyURLButton from "@/components/CopyURLButton";
import SongCard from "@/components/SongCard";
import { Link } from "@/types/serializers";
import { useOutletContext, useParams } from "react-router-dom";

const Project: React.FC = () => {
  const { password } = useParams<{ password: string }>();
  const outletContext = useOutletContext<{ link: Link } | null>();
  const link = outletContext?.link;

  if (!link) {
    return <div>Error: Link not found</div>;
  }

  const songs = link.songs || link.project.songs || [];

  return (
    <Container>
      <TopContainer>
        <LogoContainer>
          <Logo src={logo} alt="logo" />
          <Title>tempo</Title>
        </LogoContainer>
        <CopyURLButton />
      </TopContainer>
      <SongsContainer>
        {songs.map((song) => (
          <SongCard key={song.id} song={song} password={password || ""} />
        ))}
      </SongsContainer>
    </Container>
  );
};

export default Project;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 101px;

  padding: 34px 30px 140px 30px;
`;

const TopContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
`;

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

const Logo = styled.img`
  width: 15px;
  height: 30px;
`;

const Title = styled.h1`
  margin: 0;
  padding: 0;

  font-size: 20px;
  font-weight: 700;
`;

const SongsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 29px;

  width: 100%;
  max-width: 800px;

  margin: 0 auto;

  &::after {
    content: "";
    flex: auto;
  }

  @media (max-width: 768px) {
    vertical-gap: 60px;
  }
`;
