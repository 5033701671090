import { useState } from "react";
import styled from "styled-components";

import CloseIcon from "~/icons/close.svg?react";
import DotsIcon from "~/icons/dots.svg?react";
import DownloadIcon from "~/icons/download.svg?react";

import { Version } from "@/types/serializers";
import { track } from "@/utils/functions";
import IconButton from "./IconButton";

interface Props {
  isOpen: boolean;
  versions: Version[];
  allowDownload: boolean;
  currentVersionId: number;
  onClose: () => void;
  onVersionSelect: (version: Version) => void;
}

const formatDate = (date: string | Date): string => {
  return new Date(date).toLocaleDateString("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });
};

const PlayerModal: React.FC<Props> = ({
  isOpen,
  versions,
  allowDownload,
  currentVersionId,
  onClose,
  onVersionSelect,
}) => {
  if (!isOpen) return null;

  const handleModalClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  const [hoveredMixId, setHoveredMixId] = useState<number | null>(null);

  return (
    <Modal onClick={handleModalClick}>
      <ModalContent>
        <ModalTop>
          <ModalTitle>Mixes</ModalTitle>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </ModalTop>
        {versions.map((version, index) => (
          <MixVersionItem
            key={version.id}
            onClick={() => onVersionSelect(version)}
            $isSelected={version.id === currentVersionId}
            $allowDownload={allowDownload}
          >
            <VersionNumberWrapper>
              <VersionNumber>{index + 1}</VersionNumber>
            </VersionNumberWrapper>
            <VersionInfo>
              <VersionNameContainer>
                <VersionName title={version.filename}>
                  {version.filename}
                </VersionName>
                {Boolean(version.version_number) && (
                  <VersionLabel>v{version.version_number}</VersionLabel>
                )}
                <VersionLabel>{version.version_type}</VersionLabel>
              </VersionNameContainer>
              <VersionDate>{formatDate(version.created_at)}</VersionDate>
            </VersionInfo>
            {allowDownload && (
              <DotsIconWrapper
                onMouseEnter={() => setHoveredMixId(version.id)}
                onMouseLeave={() => setHoveredMixId(null)}
              >
                <DotsIcon />
                {hoveredMixId === version.id && (
                  <DropdownWrapper>
                    <Dropdown>
                      <DropdownItem
                        as="a"
                        href={version.file}
                        download={version.filename}
                        onClick={(e) => {
                          e.stopPropagation();
                          track("version_downloaded_player_modal", {
                            version_id: version.id,
                            song_id: version.song_id,
                          });
                        }}
                      >
                        <DownloadIcon />
                        Download mix
                      </DropdownItem>
                    </Dropdown>
                  </DropdownWrapper>
                )}
              </DotsIconWrapper>
            )}
          </MixVersionItem>
        ))}
      </ModalContent>
    </Modal>
  );
};

export default PlayerModal;

const MixVersionItem = styled.div<{
  $isSelected: boolean;
  $allowDownload: boolean;
}>`
  display: flex;
  align-items: stretch;
  padding: 12px;
  padding-left: 12px;
  padding-right: ${(props) => (props.$allowDownload ? "0px" : "12px")};
  border-radius: 8px;
  width: 100%;
  margin-bottom: 8px;
  cursor: pointer;
  transition: background-color 0.2s ease;
  background-color: ${(props) =>
    props.$isSelected ? "#2E2E2E" : "transparent"};

  &:hover {
    background-color: ${(props) => (props.$isSelected ? "#2E2E2E" : "#252525")};
  }
`;

const VersionNumberWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  margin-right: 16px;
`;

const VersionNumber = styled.span`
  color: #7e7e7e;
  text-align: center;
  font-family: Inter;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 129.412% */
  letter-spacing: -0.408px;
`;

const VersionInfo = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-width: 0; // Add this line
`;

const VersionNameContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  width: 100%;
`;

const VersionName = styled.span`
  font-size: 16px;
  font-weight: 500;
  color: #ffffff;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  flex: 1;
  min-width: 0;
`;

const VersionLabel = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 3px;
  background: #dbdbdb;
  color: #464646;

  font-size: 10px;
  font-weight: 600;

  padding: 2px 6px;

  flex-shrink: 0;
`;

const VersionDate = styled.span`
  font-size: 14px;
  color: #727272;
`;

const Modal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 16px;

  width: 500px;

  border-radius: 8px;

  background: #191919;
`;

const ModalTop = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 21px;
`;

const ModalTitle = styled.h2`
  margin: 0;
  color: #b6b6b6;

  font-size: 29px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;

  margin-left: 8px;
`;

const DotsIconWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
  position: relative;
  padding: 0 12px 0 20px;
`;

const DropdownWrapper = styled.div`
  position: absolute;
  top: calc(100% - 8px);
  right: 0px;
  padding-top: 8px;
`;

const Dropdown = styled.div`
  position: relative;
  z-index: 10;

  border-radius: 7px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  background: #191919;

  display: flex;
  padding: 10px;
  flex-direction: column;
  align-items: flex-start;
`;

const DropdownItem = styled.a`
  display: flex;
  align-items: center;
  gap: 12px;
  align-self: stretch;

  padding: 8px 12px;

  white-space: nowrap;

  cursor: pointer;

  border-radius: 10px;

  transition: background-color 0.1s ease;

  text-decoration: none;
  color: inherit;

  &:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }
`;
