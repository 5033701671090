import { link_path } from "@/routes";
import { Link } from "@/types/serializers";
import api from "@/utils/api";
import React from "react";
import { useQuery } from "react-query";
import { Outlet, useParams } from "react-router-dom";
import styled from "styled-components";

const fetchLink = async (password: string): Promise<Link> => {
  const response = await api.get(link_path(password));
  return response.data;
};

const PrivateLinkLayout: React.FC = () => {
  const { password } = useParams<{ password: string }>();

  const {
    data: link,
    isLoading,
    error,
  } = useQuery<Link, Error>(["link", password], () => fetchLink(password!), {
    enabled: !!password,
  });

  if (isLoading) return <CenteredMessage>Loading...</CenteredMessage>;
  if (error)
    return (
      <CenteredMessage>An error occurred: {error.message}</CenteredMessage>
    );
  if (!link) return <CenteredMessage>Project not found</CenteredMessage>;

  return <Outlet context={{ link }} />;
};

export default PrivateLinkLayout;

const CenteredMessage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  color: #ffffff;
  min-height: 100vh;
`;
