import { useState } from "react";
import { useOutletContext, useParams } from "react-router-dom";
import styled from "styled-components";

import Player from "@/components/Player";
import SongNav from "@/components/SongNav";
import { Link, Version } from "@/types/serializers";
import { MOBILE_SIDE_PADDING, SIDE_PADDING } from "@/utils/constants";

const Song: React.FC = () => {
  const { slug } = useParams<{ slug: string }>();
  const outletContext = useOutletContext<{ link: Link }>();

  const [currentVersion, setCurrentVersion] = useState<Version>();

  const link = outletContext.link;
  const songs = link.songs ?? link.project.songs ?? [];
  const song = songs.find((s) => s.slug === slug);

  if (!song) return <CenteredMessage>Song not found</CenteredMessage>;

  return (
    <Container>
      <SongNav
        song={song}
        currentVersion={currentVersion}
        allowDownload={link.allow_download}
      />
      <Player
        allowDownload={link.allow_download}
        song={song}
        currentVersion={currentVersion}
        setCurrentVersion={setCurrentVersion}
      />
    </Container>
  );
};

export default Song;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 101px;
  padding: 34px ${SIDE_PADDING}px;
  color: #ffffff;

  @media (max-width: 768px) {
    gap: 16px;

    padding: 8px ${MOBILE_SIDE_PADDING}px;
  }
`;

const CenteredMessage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  color: #ffffff;
`;
