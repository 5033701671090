import api from "@/utils/api";
import { format } from "date-fns";
import React from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import styled from "styled-components";

interface AnalyticsData {
  link: {
    id: number;
    name: string;
    view_count: number;
    download_count: number;
    referrers: Array<{
      domain: string;
      count: number;
      first_viewed_at: string;
    }>;
    device_types: Record<string, number>;
  };
  songs: Array<{
    id: number;
    title: string;
    play_count: number;
    versions: Array<{
      id: number;
      name: string;
      play_count: number;
      download_count: number;
    }>;
  }>;
}

const Analytics: React.FC = () => {
  const { password } = useParams<{ password: string }>();
  const { data, isLoading, error } = useQuery<AnalyticsData>(
    ["analytics", password],
    () => api.get(`/api/links/${password}/analytics`).then((res) => res.data)
  );

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>An error occurred: {(error as Error).message}</div>;
  if (!data) return <div>No data available</div>;

  return (
    <Container>
      <h1>Analytics for {data.link.name}</h1>

      <Section>
        <h2>Link Overview</h2>
        <p>Total Views: {data.link.view_count}</p>
        <p>Total Downloads: {data.link.download_count}</p>
      </Section>

      <Section>
        <h2>Device Types</h2>
        <ResponsiveContainer width="100%" height={300}>
          <BarChart
            data={Object.entries(data.link.device_types).map(
              ([type, count]) => ({ type, count })
            )}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="type" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Bar dataKey="count" fill="#8884d8" />
          </BarChart>
        </ResponsiveContainer>
      </Section>

      <Section>
        <h2>Referrers</h2>
        <Table>
          <thead>
            <tr>
              <th>Domain</th>
              <th>Count</th>
              <th>First Viewed</th>
            </tr>
          </thead>
          <tbody>
            {data.link.referrers.map((referrer) => (
              <tr key={referrer.domain}>
                <td>{referrer.domain}</td>
                <td>{referrer.count}</td>
                <td>{format(new Date(referrer.first_viewed_at), "PPP")}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Section>

      <Section>
        <h2>Songs</h2>
        {data.songs.map((song) => (
          <SongSection key={song.id}>
            <h3>{song.title}</h3>
            <p>Plays: {song.play_count}</p>
            <h4>Versions</h4>
            <Table>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Plays</th>
                  <th>Downloads</th>
                </tr>
              </thead>
              <tbody>
                {song.versions.map((version) => (
                  <tr key={version.id}>
                    <td>{version.name}</td>
                    <td>{version.play_count}</td>
                    <td>{version.download_count}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </SongSection>
        ))}
      </Section>
    </Container>
  );
};

export default Analytics;

const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
  color: #fff;
`;

const Section = styled.section`
  margin-bottom: 2rem;
`;

const SongSection = styled.div`
  margin-bottom: 1rem;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;

  th,
  td {
    border: 1px solid #444;
    padding: 0.5rem;
    text-align: left;
  }

  th {
    background-color: #333;
  }
`;
