import { useAuth } from "@/hooks/useAuth";
import { CTAButton, LandingContainer, Title } from "@/pages/Home";
import { useState } from "react";
import styled from "styled-components";

const Login: React.FC = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const { login } = useAuth();

  const handleLogin = (e: React.FormEvent) => {
    e.preventDefault();

    if (email && password) {
      login(email, password);
    }
  };

  return (
    <LoginContainer>
      <Title>Log In</Title>
      <LoginForm onSubmit={handleLogin}>
        <Input
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <Input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
        />
        <CTAButton type="submit">Log In</CTAButton>
      </LoginForm>
    </LoginContainer>
  );
};

export default Login;

// Styled components remain the same

const LoginContainer = styled(LandingContainer)`
  // Add any specific styles for the login page
`;

const LoginForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 300px;
`;

const Input = styled.input`
  width: 100%;
  padding: 0.75rem;
  margin-bottom: 1rem;
  border: 1px solid #fff;
  background-color: transparent;
  color: #fff;
  font-size: 1rem;

  &::placeholder {
    color: rgba(255, 255, 255, 0.7);
  }
`;
