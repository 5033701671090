import { Navigate, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";

import Analytics from "@/pages/Analytics";
import Home from "@/pages/Home";
import Login from "@/pages/Login";
import PrivateLinkLayout from "@/pages/PrivateLinkLayout";

import Project from "@/components/Project";
import Song from "@/components/Song";

const App: React.FC = () => {
  return (
    <>
      <Routes>
        <Route path="/v/:password" element={<PrivateLinkLayout />}>
          <Route index element={<Project />} />
          <Route path="s/:slug" element={<Song />} />
          <Route path="analytics" element={<Analytics />} />
        </Route>
        <Route path="/login" element={<Login />} />
        <Route path="/" element={<Home />} />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
      <ToastContainer
        position="bottom-right"
        closeOnClick={false}
        newestOnTop={false}
        closeButton={false}
        autoClose={5000}
        hideProgressBar
        theme="dark"
        rtl={false}
      />
    </>
  );
};

export default App;
