import styled from "styled-components";

import useWaveformDimensions from "@/hooks/useWaveformDimensions";
import useWaveformDrawing from "@/hooks/useWaveformDrawing";
import { Version } from "@/types/serializers";
import { PROGRESS_LINE_HEIGHT, PROGRESS_LINE_PADDING } from "@/utils/constants";

interface WaveformProps {
  waveformData: Version["waveform"];
  currentTime: number;
  duration: number;
  isLoading: boolean;
  onSeek: (time: number) => void;
}

const HEIGHT = PROGRESS_LINE_HEIGHT + PROGRESS_LINE_PADDING * 2;

const Waveform: React.FC<WaveformProps> = ({
  waveformData,
  currentTime,
  duration,
  isLoading,
  onSeek,
}) => {
  const { waveformWidth, numBars, containerRef } = useWaveformDimensions();

  const { canvasRef, seek } = useWaveformDrawing(
    waveformData,
    waveformWidth,
    numBars,
    currentTime,
    duration,
    onSeek,
    isLoading
  );

  return (
    <Container ref={containerRef}>
      <Canvas
        ref={canvasRef}
        width={waveformWidth}
        height={HEIGHT}
        onClick={seek}
      />
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  max-width: 100%;
  overflow: hidden;
`;

const Canvas = styled.canvas`
  width: 100%;
  height: ${HEIGHT}px;
  cursor: pointer;
`;

export default Waveform;
