import IconButton from "@/components/IconButton";
import { copyCurrentURL } from "@/utils/functions";
import LinkIcon from "~/icons/link.svg?react";

const CopyURLButton: React.FC = () => (
  <IconButton onClick={copyCurrentURL}>
    <LinkIcon />
  </IconButton>
);

export default CopyURLButton;
