import axios, { AxiosInstance } from "axios";

const getCsrfToken = () => {
  return (
    document
      .querySelector('meta[name="csrf-token"]')
      ?.getAttribute("content") || ""
  );
};

const api: AxiosInstance = axios.create({
  headers: {
    "X-CSRF-Token": getCsrfToken(),
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

export default api;
