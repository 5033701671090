import { track } from "@/utils/functions";
import { useEffect, useState } from "react";

import { Song, Version } from "@/types/serializers";
import useAudio from "./useAudio";

const useChannelSwitching = (song: Song) => {
  const [channelA, setChannelA] = useState<Version>(song.versions[0]);
  const [channelB, setChannelB] = useState<Version>(
    song.versions[1] ?? ({} as Version)
  );
  const [activeChannel, setActiveChannel] = useState<"A" | "B">("A");
  const [modalIsOpenForChannel, setModalIsOpenForChannel] = useState<
    "A" | "B" | null
  >(null);

  const {
    isPlaying,
    isLoading,
    currentTime,
    volume,
    seek,
    handlePlayPause,
    handleChangeVolume,
    handleSwitchChannels,
  } = useAudio(channelA, channelB);

  const switchChannel = (channel: "A" | "B") => {
    if (channelA.id === channelB.id) {
      return;
    }

    setActiveChannel(channel);

    handleSwitchChannels(channel);

    track("switch_channel", {
      from_version_id: channel === "A" ? channelB.id : channelA.id,
      to_version_id: channel === "A" ? channelA.id : channelB.id,
      song_id: channelA.song_id,
      current_playback_time: currentTime,
      is_playing: isPlaying,
    });
  };

  const selectVersionForChannel = (version: Version) => {
    const modalIsOpenForChannelA = modalIsOpenForChannel === "A";
    const otherChannel = modalIsOpenForChannelA ? channelB : channelA;

    if (otherChannel.id === version.id) {
      modalIsOpenForChannelA ? setChannelB(channelA) : setChannelA(channelB);
    }

    modalIsOpenForChannelA ? setChannelA(version) : setChannelB(version);

    if (version.file && !otherChannel.file) {
      setActiveChannel(modalIsOpenForChannelA ? "A" : "B");
    }

    track("select_version", {
      version_id: version.id,
      song_id: version.song_id,
      channel: modalIsOpenForChannelA ? "A" : "B",
    });
  };

  useEffect(() => {
    if (channelA.file !== channelB.file) {
      setActiveChannel(channelA.file ? "A" : "B");
    }
  }, [channelA, channelB]);

  return {
    channelA,
    channelB,
    activeChannel,
    modalIsOpenForChannel,
    setModalIsOpenForChannel,
    selectVersionForChannel,
    switchChannel,
    isPlaying,
    isLoading,
    currentTime,
    volume,
    seek,
    handlePlayPause,
    handleChangeVolume,
  };
};

export default useChannelSwitching;
