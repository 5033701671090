import styled from "styled-components";

import { Song, Version } from "@/types/serializers";
import ChannelSwitcher from "./ChannelSwitcher";
import NowPlaying from "./NowPlaying";

interface Props {
  song: Song;
  channelA: Version;
  channelB: Version;
  activeChannel: "A" | "B";
  setIsModalOpen: (isOpen: boolean) => void;
  setActiveChannel: (channel: "A" | "B") => void;
  setModalIsOpenForChannel: (channel: "A" | "B") => void;
}

const PlayerInfo: React.FC<Props> = ({
  song,
  channelA,
  channelB,
  activeChannel,
  setIsModalOpen,
  setActiveChannel,
  setModalIsOpenForChannel,
}) => (
  <Container>
    <NowPlaying song={song} />
    {Boolean(channelA.file && channelB.file) && (
      <ChannelSwitcher
        channelA={channelA}
        channelB={channelB}
        activeChannel={activeChannel}
        setIsModalOpen={setIsModalOpen}
        setActiveChannel={setActiveChannel}
        setModalIsOpenForChannel={setModalIsOpenForChannel}
      />
    )}
  </Container>
);

export default PlayerInfo;

const Container = styled.div`
  max-width: 940px;
  width: 100%;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  gap: 16px;

  @media (max-width: 767px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
  }
`;
