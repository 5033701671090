import { Song } from "@/types/serializers";
import styled from "styled-components";

interface Props {
  song: Song;
}

const NowPlaying: React.FC<Props> = ({ song }) => (
  <Container>
    <Title title={song.title}>{song.title}</Title>
    <Artist>{song.artist}</Artist>
  </Container>
);

export default NowPlaying;

const Container = styled.div`
  flex: 1;
  overflow: hidden;
  max-width: 100%;

  @media (max-width: 767px) {
    width: 100%;
  }
`;

const Title = styled.h1`
  color: #fff;
  font-size: 45px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Artist = styled.h2`
  color: #727272;
  font-size: 25px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0;
`;
