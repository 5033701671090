import { useEffect, useRef } from "react";

import {
  PROGRESS_LINE_HEIGHT,
  PROGRESS_LINE_PADDING,
  WAVEFORM_HEIGHT,
} from "@/utils/constants";
import { averageWaveformData, track } from "@/utils/functions";

const BAR_GAP = 1;
const START_COLOR = { r: 71, g: 73, b: 79 };
const END_COLOR = { r: 180, g: 180, b: 180 };
const SHOW_PROGRESS_LINE = true;
const PROGRESS_LINE_COLOR = `rgb(255, 255, 255)`;

const useWaveformDrawing = (
  waveformData: number[],
  waveformWidth: number,
  numBars: number,
  currentTime: number,
  duration: number,
  onSeek: (time: number) => void,
  isLoading: boolean
) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const animationRef = useRef<number>();
  const hoverPositionRef = useRef<{ x: number | null; y: number | null }>({
    x: null,
    y: null,
  });

  // Define timestamp height
  const TIMESTAMP_HEIGHT = 40; // Height in pixels for the timestamp area

  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas || waveformWidth === 0) return;

    const ctx = canvas.getContext("2d");
    if (!ctx) return;

    const dpr = window.devicePixelRatio || 1;
    canvas.width = waveformWidth * dpr;
    canvas.height =
      (PROGRESS_LINE_HEIGHT + PROGRESS_LINE_PADDING * 2 + TIMESTAMP_HEIGHT) *
      dpr;
    canvas.style.width = `${waveformWidth}px`;
    canvas.style.height = `${
      PROGRESS_LINE_HEIGHT + PROGRESS_LINE_PADDING * 2 + TIMESTAMP_HEIGHT
    }px`;
    ctx.scale(dpr, dpr);

    const averagedWaveformData = averageWaveformData(waveformData, numBars);
    const minWaveformValue = Math.min(...averagedWaveformData);
    const maxWaveformValue = Math.max(...averagedWaveformData);
    const waveformRange = maxWaveformValue - minWaveformValue;

    // Add mouse event listeners
    const handleMouseMove = (event: MouseEvent) => {
      const rect = canvas.getBoundingClientRect();
      const x = event.clientX - rect.left;
      const y = event.clientY - rect.top;
      hoverPositionRef.current = { x, y };
    };

    const handleMouseLeave = () => {
      hoverPositionRef.current = { x: null, y: null };
    };

    canvas.addEventListener("mousemove", handleMouseMove);
    canvas.addEventListener("mouseleave", handleMouseLeave);

    const drawWaveform = () => {
      if (!ctx) return;

      ctx.clearRect(
        0,
        0,
        waveformWidth,
        PROGRESS_LINE_HEIGHT + PROGRESS_LINE_PADDING * 2 + TIMESTAMP_HEIGHT
      );

      const progress = currentTime / duration || 0;
      const progressWidth = progress * waveformWidth;

      const totalBarWidth = waveformWidth / averagedWaveformData.length;
      const barWidth = totalBarWidth - BAR_GAP;

      const hover = hoverPositionRef.current;

      averagedWaveformData.forEach((value, index) => {
        const barHeight =
          ((value - minWaveformValue) / waveformRange) * WAVEFORM_HEIGHT;
        const x = index * totalBarWidth;
        const y =
          (PROGRESS_LINE_HEIGHT - barHeight) / 2 +
          PROGRESS_LINE_PADDING +
          TIMESTAMP_HEIGHT;

        const barProgress = Math.max(
          0,
          Math.min(1, (progressWidth - x) / totalBarWidth)
        );
        const r = Math.round(
          START_COLOR.r + (END_COLOR.r - START_COLOR.r) * barProgress
        );
        const g = Math.round(
          START_COLOR.g + (END_COLOR.g - START_COLOR.g) * barProgress
        );
        const b = Math.round(
          START_COLOR.b + (END_COLOR.b - START_COLOR.b) * barProgress
        );

        // Apply dimming effect when loading
        const alpha = isLoading ? 0.5 : 1;

        // Check if the bar is being hovered
        let barColor = `rgba(${r}, ${g}, ${b}, ${alpha})`;
        if (hover.x !== null && hover.y !== null) {
          if (progressWidth <= x && x <= hover.x) {
            // Shade between the playhead and hover position
            barColor = `rgba(${r}, ${g}, ${b}, ${alpha * 0.5})`;
          }
        }
        ctx.fillStyle = barColor;
        ctx.fillRect(x, y, barWidth, barHeight);
      });

      if (SHOW_PROGRESS_LINE) {
        ctx.beginPath();
        ctx.lineCap = "round";
        ctx.moveTo(progressWidth, PROGRESS_LINE_PADDING + TIMESTAMP_HEIGHT);
        ctx.lineTo(
          progressWidth,
          PROGRESS_LINE_HEIGHT + PROGRESS_LINE_PADDING + TIMESTAMP_HEIGHT
        );
        ctx.strokeStyle = PROGRESS_LINE_COLOR;
        ctx.lineWidth = 4;
        ctx.stroke();
      }

      // Draw the hovering timestamp
      if (hover.x !== null && hover.y !== null) {
        // Calculate the hover time
        const hoverTime = (hover.x / waveformWidth) * duration;

        // Format the hover time to mm:ss
        const minutes = Math.floor(hoverTime / 60);
        const seconds = Math.floor(hoverTime % 60);
        const formattedTime = `${minutes}:${seconds.toString().padStart(2, "0")}`;

        // Set font and style
        ctx.font = "bold 14px Arial";
        ctx.textAlign = "center";

        // Measure text dimensions
        const textMetrics = ctx.measureText(formattedTime);
        const textWidth = textMetrics.width;
        const textHeight = 10; // Approximate text height

        // Calculate text position
        const textX = hover.x;
        const textY = TIMESTAMP_HEIGHT / 2 + textHeight / 2;
        // const textY = hover.y - 20;

        // Draw background rectangle with border radius and more padding
        const padding = 8;
        const borderRadius = 4;
        ctx.fillStyle = "rgba(255, 255, 255, 1)";
        ctx.beginPath();
        ctx.roundRect(
          textX - textWidth / 2 - padding,
          textY - textHeight - padding,
          textWidth + padding * 2,
          textHeight + padding * 2,
          borderRadius
        );
        ctx.fill();

        // Draw the text
        ctx.fillStyle = "black";
        ctx.fillText(formattedTime, textX, textY);
      }

      animationRef.current = requestAnimationFrame(drawWaveform);
    };

    drawWaveform();

    return () => {
      if (animationRef.current) {
        cancelAnimationFrame(animationRef.current);
      }
      canvas.removeEventListener("mousemove", handleMouseMove);
      canvas.removeEventListener("mouseleave", handleMouseLeave);
    };
  }, [waveformData, waveformWidth, numBars, currentTime, duration, isLoading]);

  const seek = (event: React.MouseEvent<HTMLCanvasElement>) => {
    if (canvasRef.current && duration > 0) {
      const rect = canvasRef.current.getBoundingClientRect();
      const x = event.clientX - rect.left;
      const seekPosition = x / rect.width;
      const seekTime = seekPosition * duration;

      track("waveform_seek", {
        seek_time: seekTime,
        duration: duration,
      });
      onSeek(seekTime);
    }
  };

  return { canvasRef, seek };
};

export default useWaveformDrawing;
