import { Version } from "@/types/serializers";
import styled from "styled-components";

import DotsIcon from "~/icons/dots.svg?react";

interface ChannelSwitcherProps {
  channelA: Version | null;
  channelB: Version | null;
  activeChannel: "A" | "B";
  setIsModalOpen: (isOpen: boolean) => void;
  setActiveChannel: (channel: "A" | "B") => void;
  setModalIsOpenForChannel: (channel: "A" | "B") => void;
}

const ChannelSwitcher: React.FC<ChannelSwitcherProps> = ({
  channelA,
  channelB,
  activeChannel,
  setIsModalOpen,
  setActiveChannel,
  setModalIsOpenForChannel,
}) => {
  const handleOpenModal = (e: React.MouseEvent, channel: "A" | "B") => {
    e.stopPropagation();
    setModalIsOpenForChannel(channel);
    setIsModalOpen(true);
  };

  const renderChannelButton = (forChannel: "A" | "B") => {
    const isActive = forChannel === activeChannel;
    const channel = forChannel === "A" ? channelA : channelB;

    if (!channel || !channel.file) return null;

    return (
      <ButtonWrapper $isActive={isActive}>
        <LeftButton onClick={() => setActiveChannel(forChannel)}>
          <ChannelInfo>
            <VersionType title={channel.version_type}>
              {channel.version_type}
            </VersionType>
            {Boolean(channel.version_number) && (
              <VersionBox $isActive={isActive}>
                v{channel.version_number}
              </VersionBox>
            )}
          </ChannelInfo>
        </LeftButton>
        <DotsButton onClick={(e) => handleOpenModal(e, forChannel)}>
          <DotsIcon />
        </DotsButton>
      </ButtonWrapper>
    );
  };

  return (
    <Container>
      {renderChannelButton("A")}
      {renderChannelButton("B")}
    </Container>
  );
};

export default ChannelSwitcher;

const Container = styled.div`
  display: flex;
  gap: 20px;
  align-self: flex-start;

  @media (max-width: 767px) {
    width: 100%;
    gap: 10px;
  }
`;

const ButtonWrapper = styled.div<{ $isActive: boolean }>`
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  gap: 4px;
  width: 205px;
  border-radius: 8px;
  background: ${(props) => (props.$isActive ? "#7F10E1" : "#252525")};
  flex: 1;

  @media (max-width: 767px) {
    width: 100%;
    flex-basis: 0;
    flex-grow: 1;
    min-width: 0;
  }
`;

const LeftButton = styled.button`
  background: transparent;
  border: none;
  outline: none;
  font: inherit;
  color: #fff;
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 17px;
  font-weight: 500;
  padding: 15px 20px 15px 40px;
  max-width: calc(100% - 40px);
  overflow: hidden;

  @media (max-width: 767px) {
    padding: 15px 0 15px 18px;
  }

  @media (max-width: 400px) {
    font-size: 14px;
  }
`;

const ChannelInfo = styled.span`
  display: flex;
  gap: 8px;
  overflow: hidden;
`;

const DotsButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 21px 0 8px;
  height: 100%;
  border-radius: 0 8px 8px 0;
  flex-shrink: 0;
`;

const VersionType = styled.span`
  min-width: 0;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

const VersionBox = styled.div<{ $isActive: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px 6px;
  border-radius: 3px;
  background: #dbdbdb;
  color: ${(props) => (props.$isActive ? "#7F10E1" : "#252525")};
  font-size: 12px;
  font-weight: 600;
  line-height: 1;
`;
