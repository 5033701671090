import { Song } from "@/types/serializers";
import { Link } from "react-router-dom";
import styled from "styled-components";

interface Props {
  song: Song;
  password: string;
}

const SongCard: React.FC<Props> = ({ song, password }: Props) => (
  <Container to={`/v/${password}/s/${song.slug}`} state={{ song }}>
    <CoverPlaceholder />
    <Info>
      <Title title={song.title}>{song.title}</Title>
      <Artist>{song.artist}</Artist>
    </Info>
  </Container>
);

export default SongCard;

const Container = styled(Link)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 14px;
  text-decoration: none;
  transition: transform 0.2s ease-in-out;
  width: 164px;

  &:hover {
    transform: scale(1.05);
  }
`;

const Cover = styled.img`
  width: 164px;
  height: 164px;
  border-radius: 15px;
  background: #47494f;
  transition:
    transform 0.2s ease-in-out,
    filter 0.2s ease-in-out;

  ${Container}:hover & {
    transform: translateY(-5px);
    filter: brightness(1.1);
  }
`;

const CoverPlaceholder = styled.div`
  width: 164px;
  height: 164px;
  border-radius: 15px;
  background: #47494f;
  transition:
    transform 0.2s ease-in-out,
    filter 0.2s ease-in-out;

  ${Container}:hover & {
    transform: translateY(-5px);
    filter: brightness(1.1);
  }
`;

const Info = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  width: 100%;
`;

const Title = styled.h2`
  margin: 0;
  padding: 0;
  color: #fff;
  font-size: 17px;
  transition: color 0.2s ease-in-out;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;

  ${Container}:hover & {
    color: #e0e0e0;
  }
`;

const Artist = styled.h3`
  margin: 0;
  padding: 0;
  color: #7e7e7e;
  font-size: 16px;
  font-weight: 400;
  transition: color 0.2s ease-in-out;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;

  ${Container}:hover & {
    color: #a0a0a0;
  }
`;
