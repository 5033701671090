import ahoy from "ahoy.js";
import { toast } from "react-toastify";

export function formatTime(time: number): string {
  const minutes = Math.floor(time / 60);
  const seconds = Math.floor(time % 60);
  return `${minutes.toString().padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
}

export function averageWaveformData(data: number[], bars: number): number[] {
  if (data.length <= bars) return data;

  const chunkSize = Math.ceil(data.length / bars);
  const averagedData = [];

  for (let i = 0; i < data.length; i += chunkSize) {
    const chunk = data.slice(i, i + chunkSize);
    const average = chunk.reduce((sum, value) => sum + value, 0) / chunk.length;
    averagedData.push(average);
  }

  return averagedData;
}

export async function copyCurrentURL() {
  const currentUrl = window.location.href;

  try {
    await navigator.clipboard.writeText(currentUrl);
    toast.success("Link copied to clipboard!");
  } catch (err) {
    console.error("Failed to copy: ", err);
    toast.error("Failed to copy link");
  }
}

export function track(eventName: string, properties?: Record<string, any>) {
  ahoy.track(eventName, properties);
}
