import styled from "styled-components";

import { formatTime } from "@/utils/functions";

import PauseIcon from "~/icons/pause.svg?react";
import PlayIcon from "~/icons/play.svg?react";
import VolumeSlider from "./VolumeSlider";

interface Props {
  currentTime: number;
  version: { duration: number };
  isPlaying: boolean;
  volume: number;
  isLoading: boolean;
  handlePlayPause: () => void;
  handleChangeVolume: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const PlayerControls: React.FC<Props> = ({
  currentTime,
  version,
  isPlaying,
  volume,
  isLoading,
  handlePlayPause,
  handleChangeVolume,
}) => {
  return (
    <Container>
      <TimeContainer>
        <CurrentTimeDisplay>{formatTime(currentTime)}</CurrentTimeDisplay>{" "}
        <RemainingTime>/ {formatTime(version.duration)}</RemainingTime>
      </TimeContainer>
      <PausePlayNavControls>
        {/* <ShuffleIcon /> */}
        {/* <PrevIcon /> */}
        <PlayPauseButton onClick={handlePlayPause} disabled={isLoading}>
          {isPlaying ? (
            <PauseIcon width={36} height={36} />
          ) : (
            <PlayIcon width={40} height={40} />
          )}
        </PlayPauseButton>
        {/* <NextIcon /> */}
        {/* <RepeatIcon /> */}
      </PausePlayNavControls>
      <TransportControls>
        {/* <ShuffleIcon width={24} height={24} /> */}
        {/* <RepeatIcon width={24} height={24} /> */}

        <VolumeSlider value={volume} onChange={handleChangeVolume} />
      </TransportControls>
    </Container>
  );
};

export default PlayerControls;


const Container = styled.div`
  max-width: 940px;
  width: 100%;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 54px;
  padding: 0;

  @media (max-width: 767px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: auto;
    gap: 24px;
  }
`;

const PlayPauseButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 64px;
  height: 64px;
  background-color: white;
  color: #191919;
  border: none;
  border-radius: 50%;
  font-size: 16px;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);

  transition: all 0.2s ease-in-out;

  &:hover {
    transform: scale(1.05);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }

  &:active {
    transform: scale(0.95);
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;

    &:hover,
    &:active {
      transform: none;
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    }
  }
`;

const TimeContainer = styled.span`
  display: inline-flex;
  gap: 4px;
  align-items: center;
  color: #fff;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: 120px; // Set a fixed width for the time container
  user-select: none; // Prevent text selection

  @media (max-width: 767px) {
    width: unset;
  }
`;

const CurrentTimeDisplay = styled.span`
  min-width: 40px;
  text-align: right;
`;

const RemainingTime = styled.span`
  color: #727272;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  flex-grow: 1; // Allow this to grow and take remaining space
  text-align: left;
`;

const PausePlayNavControls = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;

  @media (min-width: 767px) {
    & > svg:nth-child(1),
    & > svg:last-child {
      display: none;
    }
  }

  @media (max-width: 767px) {
    // justify-content: space-between;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
`;

const TransportControls = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;

  @media (max-width: 767px) {
    width: 100%;
    & > svg:nth-child(1),
    & > svg:nth-child(2) {
      display: none;
    }
  }

  @supports (-webkit-touch-callout: none) {
    @media (max-width: 767px) {
        display: none;
      }
  }
`;
