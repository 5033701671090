import { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";

import { Version } from "@/types/serializers";
import { track } from "@/utils/functions";

// Extend the Navigator interface to include audioSession
interface Navigator {
  audioSession?: {
    type: string;
  };
}



function useAudio(channelA?: Version, channelB?: Version) {
  const [isPlaying, setIsPlaying] = useState<boolean>(false);
  const [currentTime, setCurrentTime] = useState<number>(0);
  const [volume, setVolume] = useState<number>(() => {
    const savedVolume = localStorage.getItem("audioVolume");
    return savedVolume ? parseFloat(savedVolume) : 1;
  });
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const audioContextRef = useRef<AudioContext | null>(null);
  const gainARef = useRef<GainNode | null>(null);
  const gainBRef = useRef<GainNode | null>(null);
  const bufferARef = useRef<AudioBuffer | null>(null);
  const bufferBRef = useRef<AudioBuffer | null>(null);
  const currentSourceRef = useRef<AudioBufferSourceNode | null>(null);
  const currentSongRef = useRef<"A" | "B">(
    channelA?.file ? "A" : channelB?.file ? "B" : "A"
  );

  const startTimeRef = useRef<number>(0);
  const pauseTimeRef = useRef<number>(0);

  const isInitializedRef = useRef<boolean>(false);
  const initAudioPromiseRef = useRef<Promise<void> | null>(null);
  const animationFrameIdRef = useRef<number | null>(null);

  async function initAudio(): Promise<void> {
    if (isInitializedRef.current) return;
    if (initAudioPromiseRef.current) {
      await initAudioPromiseRef.current;
      return;
    }

    setIsLoading(true);

    try {
      initAudioPromiseRef.current = (async () => {
        audioContextRef.current = new (window.AudioContext ||
          (window as any).webkitAudioContext)();

        // Set audio session type to "playback" for iOS 17+
        if ((navigator as Navigator).audioSession) {
          (navigator as Navigator).audioSession!.type = "playback";
        }

        const setupChannel = async (
          channel: Version | undefined,
          gainRef: React.MutableRefObject<GainNode | null>,
          bufferRef: React.MutableRefObject<AudioBuffer | null>
        ) => {
          if (channel?.file) {
            gainRef.current = audioContextRef.current!.createGain();

            gainRef.current.connect(audioContextRef.current!.destination);

            const maxGain = channel.max_volume_gain ?? 1;

            gainRef.current.gain.value = volume * maxGain;

            bufferRef.current = await loadAudio(channel);
          }
        };

        await Promise.all([
          setupChannel(channelA, gainARef, bufferARef),
          setupChannel(channelB, gainBRef, bufferBRef),
        ]);

        isInitializedRef.current = true;
      })();

      await initAudioPromiseRef.current;
    } catch (error) {
      toast.error(
        `Error initializing audio: ${error instanceof Error ? error.message : "Unknown error"}`
      );
    } finally {
      initAudioPromiseRef.current = null;

      setIsLoading(false);
    }
  }

  async function loadAudio(channel: Version): Promise<AudioBuffer> {
    if (!channel.file) {
      return Promise.reject("No URL provided");
    }

    if (audioContextRef.current === null) {
      throw new Error("AudioContext is not initialized");
    }

    try {
      track("audio_load_started", {
        version_id: channel.id,
        song_id: channel.song_id,
      });

      const response = await fetch(channel.mp3 ?? channel.file);
      const arrayBuffer = await response.arrayBuffer();
      const audioBuffer =
        await audioContextRef.current.decodeAudioData(arrayBuffer);

      track("audio_load_completed", {
        version_id: channel.id,
        song_id: channel.song_id,
      });

      return audioBuffer;
    } catch (error) {
      track("audio_load_error", {
        version_id: channel.id,
        song_id: channel.song_id,
        error_message: (error as Error).message,
      });

      toast.error("Failed to load audio. Please try again later.");

      throw error;
    }
  }

  function playAudio(
    buffer: AudioBuffer,
    gain: GainNode,
    offset: number = 0
  ): void {
    if (currentSourceRef.current) {
      currentSourceRef.current.stop();
    }

    const source = audioContextRef.current!.createBufferSource();
    source.buffer = buffer;
    source.connect(gain);
    source.start(0, offset);

    currentSourceRef.current = source;

    startTimeRef.current = audioContextRef.current!.currentTime - offset;

    setIsPlaying(true);
  }

  const getCurrentChannelAudio = (): {
    buffer: AudioBuffer;
    gain: GainNode;
    version: Version | undefined;
  } | null => {
    if (
      currentSongRef.current === "A" &&
      bufferARef.current &&
      gainARef.current
    ) {
      return {
        buffer: bufferARef.current,
        gain: gainARef.current,
        version: channelA,
      };
    } else if (
      currentSongRef.current === "B" &&
      bufferBRef.current &&
      gainBRef.current
    ) {
      return {
        buffer: bufferBRef.current,
        gain: gainBRef.current,
        version: channelB,
      };
    }
    return null;
  };

  // Toggle play/pause
  async function handlePlayPause(): Promise<void> {
    if (isLoading) return;

    await initAudio();

    if (isPlaying) {
      pauseTimeRef.current =
        audioContextRef.current!.currentTime - startTimeRef.current;

      currentSourceRef.current!.stop();

      setIsPlaying(false);

      const version = currentSongRef.current === "A" ? channelA : channelB;
      track("audio_paused", {
        version_id: version?.id,
        song_id: version?.song_id,
        current_playback_time: pauseTimeRef.current,
      });
    } else {
      const currentAudio = getCurrentChannelAudio();

      if (!currentAudio) {
        toast.warn("Current song is not available.");

        track("audio_play_error", {
          version_id:
            currentSongRef.current === "A" ? channelA?.id : channelB?.id,
          song_id:
            currentSongRef.current === "A"
              ? channelA?.song_id
              : channelB?.song_id,
          error_message: "Current song is not available",
        });

        return;
      }

      playAudio(currentAudio.buffer, currentAudio.gain, pauseTimeRef.current);

      const version = currentSongRef.current === "A" ? channelA : channelB;
      track("audio_played", {
        version_id: version?.id,
        song_id: version?.song_id,
        current_playback_time: pauseTimeRef.current,
      });
    }
  }

  const adjustChannelVolume = (
    gainRef: React.MutableRefObject<GainNode | null>,
    channel: Version | undefined,
    newVolume: number,
    currentTime: number
  ) => {
    if (gainRef.current && channel) {
      const maxGain = channel.max_volume_gain ?? 1;
      gainRef.current.gain.setValueAtTime(newVolume * maxGain, currentTime);
    }
  };

  // Handle volume change
  function handleChangeVolume(e: React.ChangeEvent<HTMLInputElement>): void {
    const newVolume = parseFloat(e.target.value);

    const currentTime = audioContextRef.current?.currentTime ?? 0;
    adjustChannelVolume(gainARef, channelA, newVolume, currentTime);
    adjustChannelVolume(gainBRef, channelB, newVolume, currentTime);

    setVolume(newVolume);
    localStorage.setItem("audioVolume", newVolume.toString());
  }

  async function handleSwitchChannels(channel: "A" | "B"): Promise<void> {
    if (isLoading) return;

    await initAudio();

    const channelData = {
      A: {
        buffer: bufferARef.current,
        gain: gainARef.current,
        version: channelA,
      },
      B: {
        buffer: bufferBRef.current,
        gain: gainBRef.current,
        version: channelB,
      },
    };

    if (!channelData[channel].buffer) return;

    const offset = isPlaying
      ? audioContextRef.current!.currentTime - startTimeRef.current
      : pauseTimeRef.current;

    currentSourceRef.current?.stop();

    currentSongRef.current = channel;
    pauseTimeRef.current = offset;

    if (isPlaying) {
      playAudio(
        channelData[channel].buffer!,
        channelData[channel].gain!,
        offset
      );

      track("audio_played", {
        version_id: channelData[channel].version?.id,
        song_id: channelData[channel].version?.song_id,
        current_playback_time: offset,
      });
    }
  }

  // Seek to a specific time in the audio
  function seek(time: number): void {
    if (isLoading || !isInitializedRef.current) return;

    currentSourceRef.current?.stop();

    const currentAudio = getCurrentChannelAudio();

    if (!currentAudio?.buffer || !currentAudio.gain) {
      toast.warn("Current song is not available.");
      return;
    }

    playAudio(currentAudio.buffer, currentAudio.gain, time);
    pauseTimeRef.current = time;
  }

  // Update currentTime more smoothly using requestAnimationFrame
  useEffect(() => {
    const updateCurrentTime = () => {
      if (!isPlaying || !audioContextRef.current) return;

      const elapsed =
        audioContextRef.current.currentTime - startTimeRef.current;

      setCurrentTime(elapsed);

      const channelData = {
        A: { buffer: bufferARef.current },
        B: { buffer: bufferBRef.current },
      };

      const currentBuffer = channelData[currentSongRef.current].buffer;

      if (!currentBuffer) {
        toast.warn("Current song is not available.");
        return;
      }

      if (elapsed >= currentBuffer.duration) {
        setIsPlaying(false);
        currentSourceRef.current?.stop();

        // Reset to the beginning
        setCurrentTime(0);
        pauseTimeRef.current = 0;

        return;
      }

      animationFrameIdRef.current = requestAnimationFrame(updateCurrentTime);
    };

    if (isPlaying) {
      animationFrameIdRef.current = requestAnimationFrame(updateCurrentTime);
    }

    return () => {
      if (animationFrameIdRef.current) {
        cancelAnimationFrame(animationFrameIdRef.current);
      }
    };
  }, [isPlaying]);

  // Handle URL changes
  useEffect(() => {
    const resetAudioState = () => {
      // Stop and clean up the current audio source
      if (currentSourceRef.current) {
        currentSourceRef.current.stop();
        currentSourceRef.current = null;
      }

      // Close the existing audio context
      if (audioContextRef.current) {
        audioContextRef.current.close();
        audioContextRef.current = null;
      }

      // Reset references and playback states
      isInitializedRef.current = false;
      bufferARef.current = null;
      bufferBRef.current = null;
      gainARef.current = null;
      gainBRef.current = null;
      startTimeRef.current = 0;
      pauseTimeRef.current = 0;

      // Reset UI states
      setCurrentTime(0);
      setIsPlaying(false);
    };

    const updateCurrentSongReference = () => {
      currentSongRef.current = channelA?.file
        ? "A"
        : channelB?.file
          ? "B"
          : "A";
    };

    (async () => {
      if (initAudioPromiseRef.current) {
        await initAudioPromiseRef.current;
        return;
      }

      resetAudioState();
      updateCurrentSongReference();

      // Re-initialize audio without auto-playing
      await initAudio();
    })();
  }, [channelA, channelB]);

  // Cleanup on unmount
  useEffect(() => {
    const cleanupAudioResources = () => {
      if (!isInitializedRef.current) return;

      // Stop and disconnect the current audio source
      if (currentSourceRef.current) {
        currentSourceRef.current.stop();
        currentSourceRef.current.disconnect();
        currentSourceRef.current = null;
      }

      // Close the AudioContext
      if (audioContextRef.current) {
        audioContextRef.current.close();
        audioContextRef.current = null;
      }

      // Reset all refs
      bufferARef.current = null;
      bufferBRef.current = null;
      gainARef.current = null;
      gainBRef.current = null;
      isInitializedRef.current = false;
      startTimeRef.current = 0;
      pauseTimeRef.current = 0;

      // Cancel any ongoing animation frames
      if (animationFrameIdRef.current) {
        cancelAnimationFrame(animationFrameIdRef.current);
        animationFrameIdRef.current = null;
      }
    };

    return cleanupAudioResources;
  }, []);

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === "visible" && audioContextRef.current) {
        audioContextRef.current.resume().catch((error) => {
          console.error("Failed to resume audio context:", error);
        });
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);

  return {
    isPlaying,
    isLoading,
    currentTime,
    volume,
    handlePlayPause,
    handleChangeVolume,
    handleSwitchChannels,
    seek,
  };
}

export default useAudio;
