import styled from "styled-components";

interface Props {
  children: React.ReactNode;
  onClick?: () => void;
  disabled?: boolean;
  type?: "button" | "submit" | "reset";
}

const IconButton: React.FC<Props> = ({
  children,
  onClick,
  disabled,
  type = "button",
}) => (
  <Container onClick={onClick} disabled={disabled} type={type}>
    {children}
  </Container>
);

export default IconButton;

const Container = styled.button`
  width: 44px;
  height: 44px;

  display: flex;
  justify-content: center;
  align-items: center;

  padding: 0;

  border: none;
  border-radius: 7px;

  cursor: pointer;

  background: rgba(255, 255, 255, 0.08);

  transition: background-color 0.2s ease;

  &:hover {
    background: rgba(255, 255, 255, 0.12);
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;
