import { StrictMode } from "react";
import ReactDOM from "react-dom/client";
import { QueryClient, QueryClientProvider } from "react-query";
import { BrowserRouter as Router } from "react-router-dom";

import App from "@/App";
import GlobalStyles from "@/components/GlobalStyles";
import { AuthProvider } from "@/context/AuthContext";

const queryClient = new QueryClient();

ReactDOM.createRoot(document.getElementById("root")!).render(
  <StrictMode>
    <GlobalStyles />
    <QueryClientProvider client={queryClient}>
      <AuthProvider client={queryClient}>
        <Router>
          <App />
        </Router>
      </AuthProvider>
    </QueryClientProvider>
  </StrictMode>
);
