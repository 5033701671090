import { Link } from "react-router-dom";
import styled from "styled-components";

import { useAuth } from "@/hooks/useAuth";

const Home: React.FC = () => {
  const { isLoggedIn, isLoading, logout } = useAuth();

  if (isLoading) return null;

  return (
    <LandingContainer>
      {isLoggedIn ? (
        <>
          <Title>Welcome Back!</Title>
          <Subtitle>You are now logged in.</Subtitle>
          <CTAButton onClick={logout}>Log Out</CTAButton>
        </>
      ) : (
        <>
          <Title>Welcome to Our App</Title>
          <Subtitle>
            Discover amazing features and boost your productivity
          </Subtitle>
          <CTAButton as={Link} to="/login">
            Log In
          </CTAButton>
        </>
      )}
    </LandingContainer>
  );
};

export default Home;

export const LandingContainer = styled.div`
  color: #fff;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

export const Title = styled.h1`
  font-size: 3rem;
  margin-bottom: 1rem;
`;

const Subtitle = styled.h2`
  font-size: 1.5rem;
  margin-bottom: 2rem;
`;

export const CTAButton = styled.button`
  background-color: #fff;
  color: #000;
  border: none;
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    background-color: #f0f0f0;
    transform: translateY(-2px);
  }
`;
