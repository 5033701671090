import React, { createContext } from "react";
import { QueryClient, useMutation, useQuery } from "react-query";

import {
  destroy_user_session_path,
  new_user_session_path,
  users_me_path,
} from "@/routes";
import { User } from "@/types/serializers";
import api from "@/utils/api";

interface AuthContextType {
  currentUser: User | null;
  isLoading: boolean;
  isLoggedIn: boolean;
  login: (email: string, password: string) => Promise<void>;
  logout: () => Promise<void>;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider: React.FC<{
  children: React.ReactNode;
  client: QueryClient;
}> = ({ children, client: queryClient }) => {
  const {
    data: currentUser,
    isLoading,
    isSuccess,
  } = useQuery({
    queryKey: ["authStatus"],
    queryFn: async () => {
      try {
        const response = await api.get(users_me_path());
        return response.data;
      } catch (error) {
        return null;
      }
    },
  });

  const loginMutation = useMutation({
    mutationFn: async ({
      email,
      password,
    }: {
      email: string;
      password: string;
    }) => {
      const response = await api.post(new_user_session_path(), {
        user: { email, password },
      });
      return response.data;
    },
    onSuccess: (data) => {
      queryClient.setQueryData(["authStatus"], data);
      window.location.href = "/";
    },
  });

  const logoutMutation = useMutation({
    mutationFn: async () => {
      await api.delete(destroy_user_session_path());
    },
    onSuccess: () => {
      queryClient.setQueryData(["authStatus"], null);
      window.location.href = "/";
    },
  });

  const login = async (email: string, password: string) => {
    try {
      await loginMutation.mutateAsync({ email, password });
    } catch (error) {
      throw new Error("Login failed");
    }
  };

  const logout = async () => {
    await logoutMutation.mutateAsync();
  };

  const isLoggedIn = !!currentUser && isSuccess;

  return (
    <AuthContext.Provider
      value={{ isLoggedIn, currentUser, login, logout, isLoading }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
