import { useEffect, useRef, useState } from "react";

const MIN_BARS = 150;
const MAX_BARS = 800;

const useWaveformDimensions = () => {
  const [waveformWidth, setWaveformWidth] = useState(0);
  const [numBars, setNumBars] = useState(MIN_BARS);

  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const updateWaveformWidth = () => {
      if (containerRef.current) {
        const newWidth = containerRef.current.clientWidth;

        setWaveformWidth(newWidth);

        const calculatedBars = Math.min(
          Math.max(Math.floor(newWidth / 3), MIN_BARS),
          MAX_BARS
        );

        setNumBars(calculatedBars);
      }
    };

    updateWaveformWidth();

    window.addEventListener("resize", updateWaveformWidth);

    return () => {
      window.removeEventListener("resize", updateWaveformWidth);
    };
  }, []);

  return { waveformWidth, numBars, containerRef };
};

export default useWaveformDimensions;
