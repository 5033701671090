import { useEffect, useState } from "react";
import styled from "styled-components";

import useChannelSwitching from "@/hooks/useChannelSwitching";
import { Song, Version } from "@/types/serializers";

import { MOBILE_SIDE_PADDING, SIDE_PADDING } from "@/utils/constants";
import PlayerControls from "./PlayerControls";
import PlayerInfo from "./PlayerInfo";
import PlayerModal from "./PlayerModal";
import Waveform from "./Waveform";

interface Props {
  song: Song;
  allowDownload: boolean;
  currentVersion?: Version;
  setCurrentVersion?: (version: Version) => void;
}

const Player: React.FC<Props> = ({
  song,
  allowDownload,
  currentVersion,
  setCurrentVersion,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const {
    channelA,
    channelB,
    activeChannel,
    modalIsOpenForChannel,
    setModalIsOpenForChannel,
    selectVersionForChannel,
    switchChannel,
    isPlaying,
    currentTime,
    volume,
    isLoading,
    seek,
    handlePlayPause,
    handleChangeVolume,
  } = useChannelSwitching(song);

  useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
      if (event.code === "Space") {
        event.preventDefault();
        handlePlayPause();
      } else if (event.key.toLowerCase() === "a") {
        switchChannel("A");
      } else if (event.key.toLowerCase() === "b" && channelB.file) {
        switchChannel("B");
      }
    };

    document.addEventListener("keydown", handleKeyPress);

    return () => document.removeEventListener("keydown", handleKeyPress);
  }, [handlePlayPause, switchChannel, song.versions, channelB]);

  useEffect(() => {
    const newCurrentVersion = activeChannel === "A" ? channelA : channelB;
    if (setCurrentVersion) setCurrentVersion(newCurrentVersion);
  }, [activeChannel, channelA, channelB, setCurrentVersion]);

  const versionsWithFiles = song.versions.filter((v) => v.file);

  const currentVersionId =
    modalIsOpenForChannel === "A" ? channelA.id : channelB.id;

  const handleVersionSelect = (version: Version) => {
    selectVersionForChannel(version);
    setIsModalOpen(false);
  };

  return (
    <Container>
      <PlayerModal
        isOpen={isModalOpen}
        versions={versionsWithFiles}
        allowDownload={allowDownload}
        currentVersionId={currentVersionId}
        onClose={() => setIsModalOpen(false)}
        onVersionSelect={handleVersionSelect}
      />
      <PlayerInfo
        song={song}
        channelA={channelA}
        channelB={channelB}
        activeChannel={activeChannel}
        setIsModalOpen={setIsModalOpen}
        setActiveChannel={switchChannel}
        setModalIsOpenForChannel={setModalIsOpenForChannel}
      />
      {currentVersion && (
        <>
          <WaveformContainer>
            <Waveform
              waveformData={currentVersion?.waveform}
              currentTime={currentTime}
              duration={currentVersion.duration}
              isLoading={isLoading}
              onSeek={seek}
            />
          </WaveformContainer>
          <PlayerControls
            currentTime={currentTime}
            version={currentVersion}
            isPlaying={isPlaying}
            volume={volume}
            isLoading={isLoading}
            handlePlayPause={handlePlayPause}
            handleChangeVolume={handleChangeVolume}
          />
        </>
      )}
    </Container>
  );
};

export default Player;

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 100px;

  @media (max-width: 768px) {
    gap: 16px;
  }
`;

const WaveformContainer = styled.div`
  width: calc(100% + ${SIDE_PADDING * 2}px);
  left: -30px;

  @media (max-width: 768px) {
    width: calc(100% + ${MOBILE_SIDE_PADDING * 2}px);
  }
`;
