import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";

import SvgVolumeIcon from "~/icons/volume.svg?react";

type Props = {
  value: number;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

const VolumeSlider: React.FC<Props> = ({ value, onChange }: Props) => {
  const [isDragging, setIsDragging] = useState(false);
  const [displayValue, setDisplayValue] = useState(value);

  const sliderRef = useRef<HTMLDivElement>(null);
  const displayValueRef = useRef(displayValue);

  useEffect(() => {
    displayValueRef.current = displayValue;
  }, [displayValue]);

  useEffect(() => {
    let animationFrameId: number;

    const animate = () => {
      const difference = value - displayValueRef.current;

      if (Math.abs(difference) < 0.001) {
        setDisplayValue(value);
        displayValueRef.current = value;
        return;
      }

      const newDisplayValue = displayValueRef.current + difference * 0.1;
      setDisplayValue(newDisplayValue);
      displayValueRef.current = newDisplayValue;

      animationFrameId = requestAnimationFrame(animate);
    };

    animate();

    return () => {
      cancelAnimationFrame(animationFrameId);
    };
  }, [value]);

  const updateValueFromCoordinates = (clientX: number) => {
    if (sliderRef.current) {
      const rect = sliderRef.current.getBoundingClientRect();
      const x = clientX - rect.left;
      const newValue = Math.min(1, Math.max(0, x / rect.width));

      onChange({
        target: {
          value: newValue.toString(),
        },
      } as React.ChangeEvent<HTMLInputElement>);
    }
  };

  const handleStart = (clientX: number) => {
    setIsDragging(true);
    updateValueFromCoordinates(clientX);
  };

  const handleMove = (clientX: number) => {
    if (isDragging) {
      updateValueFromCoordinates(clientX);
    }
  };

  const handleEnd = () => {
    setIsDragging(false);
  };

  const handleMouseDown = (e: React.MouseEvent) => handleStart(e.clientX);
  const handleMouseMove = (e: MouseEvent) => {
    e.preventDefault();
    handleMove(e.clientX);
  };
  const handleTouchStart = (e: React.TouchEvent) =>
    handleStart(e.touches[0].clientX);
  const handleTouchMove = (e: TouchEvent) => handleMove(e.touches[0].clientX);

  const handleWheel = (e: React.WheelEvent) => {
    e.preventDefault();
    let delta = e.deltaX * 0.001;
    delta = Math.sign(delta) * Math.min(Math.abs(delta), 0.05);
    const newValue = Math.min(1, Math.max(0, value + delta));
    onChange({
      target: {
        value: newValue.toString(),
      },
    } as React.ChangeEvent<HTMLInputElement>);
  };

  useEffect(() => {
    if (isDragging) {
      document.addEventListener("mousemove", handleMouseMove);
      document.addEventListener("mouseup", handleEnd);
      document.addEventListener("touchmove", handleTouchMove);
      document.addEventListener("touchend", handleEnd);

      return () => {
        document.removeEventListener("mousemove", handleMouseMove);
        document.removeEventListener("mouseup", handleEnd);
        document.removeEventListener("touchmove", handleTouchMove);
        document.removeEventListener("touchend", handleEnd);
      };
    }
  }, [isDragging]);

  return (
    <SliderContainer
      onMouseDown={handleMouseDown}
      onTouchStart={handleTouchStart}
      onWheel={handleWheel}
    >
      <VolumeIcon />
      <Track ref={sliderRef}>
        <TrackActive style={{ width: `${displayValue * 100}%` }} />
      </Track>
      <ThumbHitbox style={{ left: `${displayValue * 100}%`, opacity: 0 }}>
        <Thumb />
      </ThumbHitbox>
    </SliderContainer>
  );
};

export default VolumeSlider;

const SLIDER_WIDTH = "150px";
const SLIDER_HEIGHT = "30px";
const TRACK_HEIGHT = "6px";
const MOBILE_TRACK_HEIGHT = "8px";
const THUMB_SIZE = "20px";
const THUMB_HITBOX_SIZE = "40px";
const BORDER_RADIUS = "50%";
const TRACK_BORDER_RADIUS = "2px";
const ACTIVE_COLOR = "#ffffff";
const INACTIVE_COLOR = "#727272";
const MOBILE_BREAKPOINT = "767px";

const SliderContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;

  position: relative;

  width: ${SLIDER_WIDTH};
  height: ${SLIDER_HEIGHT};

  box-sizing: content-box;

  padding: 0 10px;

  @media (max-width: ${MOBILE_BREAKPOINT}) {
    width: 100%;
  }
`;

const Track = styled.div`
  width: 100%;
  height: ${TRACK_HEIGHT};
  background: ${INACTIVE_COLOR};
  border-radius: ${TRACK_BORDER_RADIUS};
  position: relative;

  transition: box-shadow 0.1s ease-in-out;

  ${SliderContainer}:hover & {
    box-shadow: 0 0 4px rgba(255, 255, 255, 0.5);
  }

  @media (max-width: ${MOBILE_BREAKPOINT}) {
    height: ${MOBILE_TRACK_HEIGHT};
  }
`;

const TrackActive = styled.div`
  height: 100%;
  background: ${ACTIVE_COLOR};
  border-radius: ${TRACK_BORDER_RADIUS};
  position: absolute;
  top: 0;
  left: 0;
  transition:
    background-color 0.2s ease-in-out,
    filter 0.2s ease-in-out;

  ${SliderContainer}:hover & {
    background-color: #ffffff;
    filter: brightness(1.1);
  }
`;

const ThumbHitbox = styled.div`
  width: ${THUMB_HITBOX_SIZE};
  height: ${THUMB_HITBOX_SIZE};
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Thumb = styled.div`
  width: ${THUMB_SIZE};
  height: ${THUMB_SIZE};
  background: ${ACTIVE_COLOR};
  border-radius: ${BORDER_RADIUS};
  cursor: pointer;
`;

const VolumeIcon = styled(SvgVolumeIcon)`
  width: 28px;
  height: 28px;

  fill: #ffffff;

  margin-right: 8px;

  @media (max-width: 767px) {
    width: 32px;
    height: 32px;
  }
`;
